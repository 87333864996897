const gifts = [
  {
    icon: 'https://cdn.skillbox.pro/wbd-front/landgen-static/popups/alchemere/gifts/gift-1.png',
    title: 'Скидка 3&nbsp;000&nbsp;₽',
    description: 'На&nbsp;покупку любого курса или профессии Skillbox'
  },
  {
    icon: 'https://cdn.skillbox.pro/wbd-front/landgen-static/popups/alchemere/gifts/gift-2.png',
    title: 'Книга &laquo;Разум под контролем. Неочевидные стратегии достижения целей&raquo;',
    description: 'Научит добираться до&nbsp;цели быстрее, при этом притормаживая и&nbsp;сбавляя обороты'
  }
]

const lernaGifts = [
  {
    icon: 'https://cdn.skillbox.pro/wbd-front/landgen-static/popups/alchemere/gifts/gift-2.png',
    title: 'Гайд &laquo;Как замотивировать себя на&nbsp;учебу&raquo;',
    description: 'Простые советы, которые помогут пройти обучение и&nbsp;не&nbsp;выгореть до&nbsp;его окончания'
  }
]

const mainImage = {
  xl: {
    avif: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-xl@1x.avif',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-xl@2x.avif'
    },
    webp: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-xl@1x.webp',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-xl@2x.webp'
    },
    img: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-xl@1x.png',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-xl@2x.png'
    }
  },
  lg: {
    avif: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-lg@1x.avif',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-lg@2x.avif'
    },
    webp: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-lg@1x.webp',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-lg@2x.webp'
    },
    img: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-lg@1x.png',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-lg@2x.png'
    }
  },
  sm: {
    avif: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-sm@1x.avif',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-sm@2x.avif'
    },
    webp: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-sm@1x.webp',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-sm@2x.webp'
    },
    img: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-sm@1x.png',
      '2x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/alchemere/alchemere-gifts-sm@2x.png'
    }
  }
}

const giftWord = gifts.length > 1 ? 'подарки' : 'подарок';

export const content = {
  title: 'Спасибо, что заполнили анкету!',
  desc: `Выберите мессенджер, в&nbsp;котором вы&nbsp;готовы получить ${giftWord}`,
  gifts,
  images: {
    webp: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/landgen-static/popups/alchemere/question/question@1x.webp',
      '2x': 'https://cdn.skillbox.pro/wbd-front/landgen-static/popups/alchemere/question/question@2x.webp'
    },
    img: {
      '1x': 'https://cdn.skillbox.pro/wbd-front/landgen-static/popups/alchemere/question/question@1x.png',
      '2x': 'https://cdn.skillbox.pro/wbd-front/landgen-static/popups/alchemere/question/question@2x.png'
    }
  },
  hasInfo: true
}

export const data = {
  lead: {
    images: mainImage,
    title: `Дарим ${giftWord}!`,
    description: `Ответьте на&nbsp;7&nbsp;простых вопросов и&nbsp;получите ${giftWord}:`,
    gifts,
    buttonText: 'Ответить на вопросы'
  },
  order: {
    images: mainImage,
    title: `Мы&nbsp;получили заявку и&nbsp;дарим ${giftWord}!`,
    description: `Наш менеджер скоро свяжется с&nbsp;вами. А&nbsp;пока ответьте на&nbsp;7&nbsp;простых вопросов и&nbsp;получите ${giftWord}:`,
    gifts,
    buttonText: 'Ответить на вопросы'
  },
  trial: {
    images: mainImage,
    title: `Мы&nbsp;получили заявку и&nbsp;дарим ${giftWord}!`,
    description: `Наш менеджер скоро свяжется с&nbsp;вами и&nbsp;откроет доступ к&nbsp;курсу. А&nbsp;пока ответьте на&nbsp;7&nbsp;простых вопросов и&nbsp;получите ${giftWord}:`,
    gifts,
    buttonText: 'Ответить на вопросы'
  },
  lerna: {
    images: mainImage,
    title: 'Дарим подарок!',
    description: `Наш менеджер свяжется с&nbsp;вами. А&nbsp;пока выберите мессенджер и&nbsp;заберите подарок:`,
    gifts: lernaGifts,
    messengers: typeof lernaThanksMessengers !== 'undefined' ? lernaThanksMessengers : [], // eslint-disable-line
  },
  tochka: {
    images: mainImage,
    title: 'Поздравляем с&nbsp;регистрацией на&nbsp;вебинар! В&nbsp;подарок&nbsp;&mdash; полезные бонусы!',
    description: `Ссылку на&nbsp;вебинар вы&nbsp;получите на&nbsp;электронную почту. А&nbsp;пока у&nbsp;вас
                  есть замечательная возможность получить подарки, ответив на&nbsp;7&nbsp;несложных вопросов:`,
    gifts,
    buttonText: 'Ответить на вопросы'
  },
}

export const lernaSchedule = [
  {
    title: 'В ближайшее время',
    value: '1'
  },
  {
    title: 'с 9:00 до 12:00',
    value: '2'
  },
  {
    title: 'с 12:00 до 16:00',
    value: '3'
  },
  {
    title: 'с 16:00 до 20:00',
    value: '4'
  }
]
