import { closePopup } from '../popups'
import { AlchemereThanksModal } from './AlchemereThanksModal'
import { createAlchemereLernaThanksModal } from './AlchemereLernaThanksModal'
import { LernaScheduleModal } from './LernaScheduleModal'

export const showAlchemerePopup = (type) => {
  const isLerna = typeof isLernaLanding !== 'undefined' && isLernaLanding // eslint-disable-line

  if (typeof anketologUrl === 'undefined') {
    return null
  }

  // Трекаем открытие анкеты алхимера после оплаты/отправки заявки в форме оплаты
  if (type === 'order') {
    window.$analytics?.push('fl-order', window.$paymentData)
  }

  closePopup()

  if (isLerna) {
    new LernaScheduleModal({
      async onClose() {
        const alchemereLernaThanksModal = await createAlchemereLernaThanksModal()

        alchemereLernaThanksModal.init()
      }
    }).init()
  } else {
    // TODO: временный костыль для ленда sales_management_skillbox_x_tochka (нужно будет удалить когда ленд удалят)
    type = location.pathname.includes('sales_management_skillbox_x_tochka') ? 'tochka' : type

    new AlchemereThanksModal(type, anketologUrl).init() // eslint-disable-line
  }
}
